<template>
  <div class="card grid-x align-center align-middle">
    <div class="cell shrink">
      <div
        v-if="!showForm"
        @click="openForm"
        class="button-wrapper" role="button">
        <plus-circle-icon/>
        <span class="button-text">Crear</span>
      </div>

      <template v-else>
        <div
          v-if="displayFields"
          class="form">
          <div class="form-group">
            <label
              for="input-name"
              class="form-label required">Nombre</label>
            <input
              ref="inputName"
              v-model.trim="campaign.name"
              @keyup.enter="openExtraFields"
              id="input-name"
              class="form-input lg"
              :class="{ 'has-error': hasError }"
              type="text"
              autocomplete="off"/>
          </div>
        </div>

        <div
          v-if="displayExtraFields"
          class="form">
          <div class="form-group">
            <label
              for="input-due-date"
              class="form-label">Día de corte</label>
            <select
              ref="inputDueDate"
              v-model.number="campaign.dueDate"
              class="form-input sm"
              id="input-due-date">
              <option
                v-for="(option, index) in dueDateOptions"
                :key="index"
                :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label
              for="input-active"
              class="form-label">Activa</label>
            <input
              v-model="campaign.active"
              id="input-active"
              class="form-input"
              type="checkbox"/>
          </div>

          <div class="form-group">
            <label
              for="input-billable"
              class="form-label">Facturable</label>
            <input
              v-model="campaign.billable"
              id="input-billable"
              class="form-input"
              type="checkbox"/>
          </div>
        </div>

        <div class="footer">
          <button
            @click="closeForm"
            class="button transparent alert">Cancelar
          </button>

          <button
            v-if="displayFields && isOwner"
            @click="openExtraFields"
            class="button transparent primary">Siguiente
          </button>

          <button
            v-else
            @click="save"
            class="button transparent">Guardar
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOwner: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      showForm: false,
      displayFields: true,
      displayExtraFields: false,
      hasError: false,
      campaign: {
        name: '',
        dueDate: 1,
        active: false,
        billable: false,
      },
      dueDateOptions: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '10',
          value: 10,
        },
        {
          label: '15',
          value: 15,
        },
        {
          label: '20',
          value: 20,
        },
        {
          label: 'Fin de mes',
          value: 30,
        },
      ],
    };
  },
  watch: {
    /* eslint-disable-next-line */
    'campaign.name': function () {
      this.hasError = false;
    },
  },
  methods: {
    openForm() {
      this.showForm = true;

      this.$nextTick(() => {
        this.$refs.inputName.focus();
      });
    },
    closeForm() {
      this.showForm = false;
      this.displayFields = true;
      this.displayExtraFields = false;
    },
    resetForm() {
      this.campaign.name = '';
      this.campaign.dueDate = 1;
      this.campaign.active = false;
      this.campaign.billable = false;
    },
    save() {
      if (this.campaign.name.length > 0) {
        const data = Object.assign({}, this.campaign);

        this.$emit('saved', data);
        this.closeForm();
        this.resetForm();
      } else {
        this.hasError = true;
        this.$refs.inputName.focus();
      }
    },
    openExtraFields() {
      if (this.campaign.name.length > 0) {
        if (this.isOwner) {
          this.displayFields = false;
          this.displayExtraFields = true;

          this.$nextTick(() => {
            this.$refs.inputDueDate.focus();
          });
        } else {
          this.save();
        }
      } else {
        this.hasError = true;
        this.$refs.inputName.focus();
      }
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_campaign-card-create.scss';
</style>
